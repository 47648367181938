<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ruleDistribution' }"
          >客户分配规则</el-breadcrumb-item
        >
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        v-loading="formLoading"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-form-item
          class="input"
          label="择咨询师："
          label-position="left"
          prop="counselor_id"
        >
          <el-select
            v-model="form.counselor_id"
            placeholder="请选择咨询师"
            style="width: 200px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="input"
          label="每天分配数量"
          label-position="left"
          prop="everyday_distribution"
        >
          <el-input-number
            v-model="form.everyday_distribution"
            label="每天分配数量"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存</el-button
          >
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "add",
  data() {
    return {
      loading: false,
      form: {},
      formLoading: false,
      options: [],
      rules: {
        counselor_id: [
          { required: true, trigger: "change", message: "请选择咨询师" },
        ],
        everyday_distribution: [
          { required: true, trigger: "change", message: "请输入每天分配数量" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("ruleDistribution", [
      "addCustomerDistributionRule",
      "selectCounselor",
    ]),
    async onSubmit() {
      let _this = this;

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          _this
            .addCustomerDistributionRule(this.form)
            .then((res) => {
              if (res.res_info) {
                _this.$message.success("新增成功！");
                _this.$router.go(-1);
              }
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
            });
        }
      });
    },
    async init() {
      this.formLoading = true;
      const { data } = await this.selectCounselor();
      this.options = data;
      this.formLoading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
